<template>
    <div>我是User页面</div>
</template>

<script>
    export default{
        name:'User',
        data(){
            return {}
        }
    }
</script>